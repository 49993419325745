import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/fussel/Documents/Entwicklung/gatsby/schoenhausen/gatsby-starter-catalyst-lithium/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="About" description="I am an astronaut who likes to visit foreign planets. Unfortuntately I have not ever built a successful rocket and so have only visited this planet. But one day this will all change." mdxType="SEO" />
    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <p>{`Donec viverra neque quis tincidunt aliquam. Fusce bibendum libero vel ligula viverra posuere. Proin convallis libero eget orci cursus ultrices.`}</p>
    <p>{`Phasellus faucibus, ex sed faucibus volutpat, `}<a parentName="p" {...{
        "href": "https://www.google.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`risus eros porttitor magna`}</a>{`, eu sodales augue magna ac justo. Donec sit amet arcu ac ante tempus accumsan. Duis posuere tortor ac eros vulputate hendrerit. Fusce imperdiet lectus pellentesque orci pellentesque, id fringilla ante gravida. Aliquam dictum nisi ac erat maximus ornare. Phasellus quis nisl ac turpis ultricies semper eu a urna. Proin sit amet auctor velit. Ut non diam metus. Nulla quis consectetur ante. Nulla lacinia, ligula vitae volutpat accumsan, ipsum urna accumsan nunc, sit amet sagittis odio turpis eget dui. Donec semper nec diam at varius. Ut non erat consequat, `}<a parentName="p" {...{
        "href": "https://www.google.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`tincidunt tortor`}</a>{` at, laoreet quam.`}</p>
    <h2 {...{
      "id": "a-list"
    }}>{`A List`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Item 1:`}</strong>{` Some kind of important thing you know. This is really important stuff. Wow!`}</li>
      <li parentName="ol"><strong parentName="li">{`Item 2:`}</strong>{` But this is more important I think…really truly. Trust me.`}</li>
      <li parentName="ol"><strong parentName="li">{`Item 3:`}</strong>{` I’m sorry. I lied. This is actually important now. I don’t know why I didn’t tell you about this before. It was just so amazing.`}</li>
      <li parentName="ol"><strong parentName="li">{`Item 4:`}</strong>{` The last one. My creativity is gone.`}</li>
    </ol>
    <h2 {...{
      "id": "a-picture"
    }}>{`A Picture`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABaTBYou5Fl//EABsQAAIDAAMAAAAAAAAAAAAAAAECAxEiABIj/9oACAEBAAEFArpiPSN899F8LKV5/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AQ2f/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACEf/aAAgBAgEBPwF0lxv/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIRIjFBYf/aAAgBAQAGPwK3Hy7HaxfTHRGOzXR0un//xAAbEAEBAQEAAwEAAAAAAAAAAAABEQAhMUFRwf/aAAgBAQABPyGmmrHwyHTqR+6p8hzCEEJ70jzk0EU7/9oADAMBAAIAAwAAABBjD//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFR/9oACAEDAQE/EE2HGH//xAAYEQEAAwEAAAAAAAAAAAAAAAABABExof/aAAgBAgEBPxAlKhVryf/EAB4QAAICAgIDAAAAAAAAAAAAAAERACExQWFxgZGh/9oACAEBAAE/ECMIyNS9aaEIzy9xSXt8iclXdhaPMHiAdLWIEgmFUT5cADogFjif/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0ddad63b2f95980481e982fcb5bd2034/d3be9/image1.webp 480w", "/static/0ddad63b2f95980481e982fcb5bd2034/e46b2/image1.webp 960w", "/static/0ddad63b2f95980481e982fcb5bd2034/62ed8/image1.webp 1800w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/0ddad63b2f95980481e982fcb5bd2034/7cc5e/image1.jpg 480w", "/static/0ddad63b2f95980481e982fcb5bd2034/6a068/image1.jpg 960w", "/static/0ddad63b2f95980481e982fcb5bd2034/d9c39/image1.jpg 1800w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/0ddad63b2f95980481e982fcb5bd2034/d9c39/image1.jpg",
            "alt": "Beakers",
            "title": "Beakers",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "now-a-long-title-and-an-unordered-list-for-your-viewing-pleasure"
    }}>{`Now a long title and an unordered list for your viewing pleasure`}</h2>
    <ul>
      <li parentName="ul">{`Everything is out of order here that is why they call it an unordered list, maybe it should have been called random list.`}</li>
      <li parentName="ul">{`More randomness and disorder.`}</li>
      <li parentName="ul">{`Now this is just straight up chaos. Chaos list to ensure that I have everything in a case of disorder or the end of times.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      